@import './variables';

/* Svg Color */

svg.facebook {
    fill: $tiphubthemeBlue;
}

.twitter {
    fill: $twitter;

    svg {
        fill: $twitter;
    }
}

/* Svg Color */

/* Listing */
@mixin listing {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Listing */


/* Events Page */
@mixin email-logo-card {    
    display: flex;
    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        li {
            background-color: rgba(0,0,0,.1);
            border-radius: 50%;
            float: left;
            margin: 0 0.57143rem;
            padding: 0.14286rem;
        }
        li:hover{                
            background-color: transparent;
            -webkit-transform: scale(.9);
            transform: scale(.9);                
        }
    }    
}
/* End Events Page */


/* rotate-down-left */
@mixin rotate-down-left {
    animation: rotate-in-down-left 2s ease infinite;

    svg {
        fill: $green;
        font-size: size(80);
    }

    @keyframes rotate-in-down-left {
        0% {
            transform-origin: left bottom;
            transform: rotate(-90deg);
            opacity: 0;
        }

        100% {
            transform-origin: left bottom;
            transform: rotate(0);
            opacity: 1;
        }
    }
}

/* rotate-down-left */

/* Button */
@mixin primaryButton {
    background-color: $tiphubthemeBlue;
    color: $white;
    border-radius: size(10);
    padding: size(10) size(22);
    font-size: size(16);
    border-color: transparent;
    font-family: $familyPrimarySemiBold;
    text-decoration: none;

    @media screen and (max-width:576px) {
        border-radius: size(6);
        padding: size(10) size(16);
        font-size: size(14);
    }
}

@mixin whiteButton {
    background-color: $white;
    color: $tiphubPrimaryTextColor;
    border-radius: size(10);
    padding: size(10) size(22);
    font-size: size(16);
    border-color: transparent;
    font-family: $familyPrimarySemiBold;
    box-shadow: $boxShadow;
    text-decoration: none;

    @media screen and (max-width:576px) {
        border-radius: size(6);
        padding: size(10) size(16);
        font-size: size(14);
    }
}

/* Button */

/* Credential Modal */
@mixin credentialForm {
    background-color: $white;
    padding: size(40);
    position: absolute;
    top: -14%;
    width: 50%;
    border-radius: size(15);

    @media screen and (min-width: 768px) and (max-width:991px) {
        top: 0%;
        width: 100%;
        padding: size(30) size(25);
    }

    @media screen and (max-width:767px) {
        width: 100%;
        padding: size(30) size(25);
    }

    .modal-header {
        padding: size(0);
        border: none;
        margin-bottom: size(25);
        position: relative;

        .btn-close {
            position: absolute;
            right: size(0);
            top: size(0);
        }
    }

    h4 {
        font-weight: 500;
        text-align: left;
    }

    .form-check {
        text-align: left;
    }

    .btn-primary {
        @include primaryButton;
        width: 100%;
    }

    .forgot {
        text-decoration: none;
    }

    .credential_router {
        display: none;

        @media screen and (max-width:991px) {
            display: block;

            .btn {
                padding-top: 0;
            }
        }
    }

    .login-bottom {
        padding: size(40) size(0) size(0);
        text-align: center;

        .seperator {
            position: relative;
            display: block;

            &::before {
                border-top: $tiphubthemeBlue solid size(1);
                content: '';
                width: 44%;
                position: absolute;
                left: 0;
                top: size(11);
            }

            &::after {
                border-top: $tiphubthemeBlue solid size(1);
                content: '';
                width: 44%;
                position: absolute;
                right: 0;
                top: size(11);
            }
        }

        .login_through_social_media {
            display: flex;
            width: 38%;
            margin: auto;

            .social-media-button {
                background-color: transparent;
                width: auto;
                padding: size(6) size(12);
            }
        }
    }
}

/* Credential Modal */

/* Tiphub Banner */

@mixin tiphub-banner {
    position: relative;

    .tiphub-banner-bg {
        position: absolute;
        background-image: linear-gradient(90deg, #daf8f9, #ffdcdb);
        height: 100%;
        width: 100%;
        // margin-top: size(-114);
        z-index: -1;
    }
}

/* Tiphub Banner */

/* Tiphub Intro */

@mixin tiphubIntro {
    padding: size(60) 0 size(100);

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: size(60) 0 size(0);
    }

    @media screen and (max-width:576px) {
        padding: size(30) 0;
    }

    h1 {
        font-family: $familyPrimaryBold;
        font-size: size(45);

        @media screen and (max-width:576px) {
            font-size: size(35);
        }
    }

    h5 {
        font-family: $familyPrimarySemiBold;
    }

    .btn-primary {
        @include primaryButton;
        padding: size(20) size(50);
        border-radius: size(16);
        font-size: size(18);
    }
}

/* Tiphub Intro */

/* supportSystemLayout */
@mixin supportSystemLayout {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    h1 {
        width: 60%;
        margin: auto;

        @media (min-width: 768px) and (max-width: 1024px) {
            width: 80%;
        }

        @media screen and (max-width:767px) {
            width: 100%;
        }
    }

    .supportSystemBox {
        padding: size(30);
        transition: all 1s ease;
        border-radius: size(10);

        img {
            height: size(45);
        }

        p {
            font-size: size(14);
            font-family: $familyPrimarySemiBold;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        a {
            font-size: size(16);
        }

        &:hover {
            box-shadow: $boxShadow;
            cursor: pointer;
        }
    }
}

/* supportSystemLayout */

/* Blog Slider */
@mixin blogSlider {
    position: relative;
    cursor: pointer;
    padding: size(60) size(0);

    .blogs-box-bg {
        margin: 0 size(10);
        border-radius: size(20);
        height: size(400);
        position: relative;

        img {
            height: 100%;
            border-radius: size(20);
            margin: auto;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: size(20);
        }

        .blog-box-content {
            padding: size(25);
            position: absolute;
            bottom: 0;
            z-index: 1;

            h1 {
                color: $white;
            }

            a {
                background-color: rgba(0, 0, 0, 0.3);
                border: $white solid size(1);
                padding: size(10) size(20);
                border-radius: size(30);
                cursor: pointer;

                svg {
                    width: size(35);
                    height: size(35);
                    fill: $white;
                }

                &:hover {
                    background-color: $white;

                    svg {
                        fill: $tiphubPrimaryTextColor;
                    }
                }
            }
        }
    }
}

/* Blog Slider */

/* Testimonial Slider Box */

@mixin testimonialSliderBox {
    .content {
        background-color: $white;
        padding: size(55);
        border-radius: size(20);
        margin: size(0) size(10);

        @media screen and (max-width:767px) {
            padding: size(15);
            border-radius: size(20);
            margin: size(0);
        }

        .titleWrapper {
            position: relative;
            height: 100%;

            .title {
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(-0%, -50%);
            }
        }
    }
}

/* Testimonial Slider Box */

/* Tipping App Icon */

@mixin tipping_app_icon {
    @include listing;
    display: inline-block;

    li {
        float: left;
        border-radius: size(10);

        a {
            position: relative;
            text-align: center;
            display: block;
            text-decoration: none;
            padding: size(20) size(15);
            width: size(105);
            height: size(105);
            border-radius: size(10);

            // img {
            //     height: size(35);
            // }

            span {
                display: block;
                margin-top: size(10)
            }
        }
    }
}

/* Tipping App Icon */

/* Accordian */
@mixin accordion {
    .accordion-item {
        margin-bottom: size(10);
        border-radius: size(10);
        background-color: rgba(255, 255, 255, 0.5);
        border: none;

        .accordion-header {
            .accordion-button {
                background-color: transparent;
                box-shadow: none;
                font-family: $familyPrimarySemiBold;
                color: $tiphubPrimaryTextColor;
                padding: size(16) size(24);

                &.collapsed {
                    background-color: $white;
                    border-radius: size(10);
                }
            }
        }

        .accordion-collapse {
            .accordion-body {
                padding: size(8) size(24) size(16);

                .tipping_app_icon {
                    @include tipping_app_icon;

                    li {
                        a {
                            img {
                                height: size(35);
                            }
                            .close {
                                position: absolute;
                                top: size(-8);
                                right: size(-8);
                                background-color: $gray-100;
                                border-radius: 50%;
                                font-size: size(20);
                                padding: size(2);
                                cursor: pointer;
                                display: none;
                            }

                            .copy {
                                position: absolute;
                                bottom: size(6);
                                right: size(4);
                                cursor: pointer;
                                display: none;
                                fill: $gray-300;
                            }

                            &:hover {
                                box-shadow: $boxShadow;

                                .close {
                                    display: block;
                                }

                                .copy {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .social_links_icon {
                    @include listing;

                    li {
                        float: left;
                        display: flex;
                        margin-right: size(8);
                        margin-bottom: size(8);

                        a {
                            padding: size(12);
                            border-radius: size(6);
                            font-size: size(20);
                            background-color: #ebeaea;
                            line-height: size(0);

                            &:hover {
                                background-color: $white;
                                box-shadow: $boxShadow;
                            }
                        }
                    }
                }

                .social_links_add {
                    .btn-primary {
                        @include primaryButton;
                        padding: size(10) size(0);

                        svg {
                            font-size: size(20);

                            path {
                                color: $white;
                            }
                        }
                    }
                }

                .qr_code {
                    width: fit-content;
                    margin: size(20) auto;
                    position: relative;
                    border: $gray-100 solid size(1);
                    padding: size(10);
                    border-radius: size(10);

                    .copy {
                        position: absolute;
                        border: $gray-100 solid size(1);
                        font-size: size(30);
                        padding: size(2);
                        border-radius: size(10);
                        bottom: size(0);
                        right: size(-50)
                    }
                }

                .qrSlider {
                    @include qrSlider;
                }

                .btn {
                    svg {
                        font-size: size(18);
                        fill: $white;
                        color: $white;
                        margin-right: size(5);

                        path {
                            fill: $white;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

/* Accordian */

/* QR Slider */

@mixin qrSlider {

    .slick-arrow {
        display: none !important;
    }

    .slick-list {

        .qrSlider-box {
            .qrSlider-box-list {
                position: relative;
                width: fit-content;
                margin: auto;

                .delete {
                    position: absolute;
                    left: size(22);
                    bottom: size(2);
                    z-index: 1;
                    font-size: size(12);
                    padding: size(1) size(6);
                    cursor: pointer;
                }

                .copy {
                    position: absolute;
                    right: size(22);
                    bottom: size(2);
                    z-index: 1;
                    cursor: pointer;
                    font-size: size(12);
                    padding: size(1) size(6);
                }
            }
        }

        .slick-slide {
            img {
                height: size(250);
                margin: auto;
            }

            &.slick-center {
                border-radius: size(8);

                img {
                    border: #0062c7 solid size(3);
                }
            }
        }
    }
}

/* Pricing */
@mixin pricing {
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
    .pricing-box {
      position: relative;
      flex: 1;
      text-align: center;
      box-shadow: 0px 0px 2px 0px rgba(162, 162, 162, 0.5);
      border-radius: size(4);
      padding: size(40) size(25);
      transition: all 0.5s ease;
      @media (max-width: 1200px) and (min-width: 768px) {
        padding: size(40) size(15);
      }
      h4 {
        font-weight: '600';
      }
      h1 {
        font-weight: '600';
        span {
          &:first-child {
            font-weight: '600';
            font-size: size(20);
          }
          &:last-child {
            font-size: size(16);
            font-weight: '500';
          }
        }
      }
      ul {
        list-style: none;
        text-align: left;
        padding: 0;
        @media (max-width: 991px) and (min-width: 768px) {
          min-height: size(110);
        }
        li {
          margin-bottom: size(8);
          background: url(../assets/img/pricing_mark.png) left size(2) no-repeat;
          padding-left: size(20);
          @media (max-width: 1200px) and (min-width: 768px) {
            font-size: size(10);
          }
          svg {
            border: $green solid size(1);
            color: $green;
            border-radius: 50%;
            margin-right: size(8);
          }
        }
      }
      .btn {
        font-weight: '600';
      }
      .recommended {
        position: absolute;
        top: size(12);
        padding: size(3) size(8);
        border-radius: size(2);
        background-color: #ebb669;
        color: $white;
        text-transform: uppercase;
        font-weight: '600';
        left: 50%;
        transform: translate(-50%, 0px);
        font-size: size(10);
      }
      &:hover,
      &.active {
         transform: scale(1.0, 1.02);
         background-color: $white;
        //  border: $green solid size(2);
         border-radius: size(2);
         z-index: 1;
         .btn {
           background-color: $green;
           color: $white;
           border-color: $green;
         }
      }
    }
  }


.mobile_view {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .destop_view {
        display: none !important;
    }

    .mobile_view {
        display: inline-block !important;
    }
}

/* QR Slider */

/* Overlay Loading*/
.mitiz_overlay{
    opacity: .5 !important;
}