@import './mixin';
@import './variables';

#root {
    font-family: $familyPrimaryRegular;
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
    position: relative;
}

/* * {
    color: $tiphubPrimaryTextColor;
} */

a {
    color: $tiphubthemeBlue !important;
    text-decoration: none !important;
}

.tiphub-frontend {
    .container {
        @media (min-width: 576px) {
            max-width: 540px;
        }

        @media (min-width: 768px) {
            max-width: 720px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1255px;
        }
    }
}

/* Header */
.tiphubHeader {
    padding: size(0);
    min-height: auto;
    background-color: transparent;
    position: relative;
    z-index: 1;
    background-image: linear-gradient(90deg, #daf8f9, #ffdcdb);


    @media screen and (max-width:576px) {
        padding-bottom: size(10);
    }

    .navbar {
        padding: size(0);

        @media screen and (max-width:767px) {
            padding-top: size(10);
        }

        .navbar-collapse {
            @media screen and (max-width:767px) {
                position: absolute;
                top: size(57);
                width: 100%;
                background-color: $white;
            }
        }

        .navbar-brand {
            width: 6%;
            padding: size(35) 0;

            @media (max-width: 767px) {
                width: 20%;
                padding: size(0);
            }

            img {
                height: size(45);

                @media (min-width: 768px) and (max-width: 1024px) {
                    height: auto;
                }

                @media (max-width: 767px) {
                    height: size(30);
                }
            }
        }

        .navbar-nav {
            @media (max-width: 767px) {
                margin: size(20) size(0);
            }

            .nav-item {
                margin: size(0) size(20);

                @media (min-width: 768px) and (max-width: 1024px) {
                    margin: size(0) size(10);
                }

                @media (max-width: 767px) {
                    margin: size(5) size(20);
                }

                .nav-link {
                    text-decoration: none;
                    font-size: size(16);
                    font-family: $familyPrimarySemiBold;
                    color: $tiphubPrimaryTextColor;

                    &.active {
                        color: $tiphubthemeBlue;
                    }
                }
            }
        }

        .btn-primary {
            @include primaryButton;
        }

        .btn-link {
            @include whiteButton;
            margin-right: size(10);

            @media screen and (max-width:576px) {
                margin-left: size(10);
            }
        }
    }

    &.sticky {
        position: sticky;
        top: 0;
        background-color: $white;
        transition: all 1s ease;
        box-shadow: $boxShadow;
        z-index: 9;
        display: flex;
        max-width: 80rem;
        margin: auto;
        border-radius: size(15);

        .navbar {
            .navbar-brand {
                padding: size(15) 0;
            }
        }
    }
}

/* Header */

/* Banner */
.tiphub-banner {
    @include tiphub-banner;

    .tiphub-banner-bg {
        height: 80%;
    }

    .tiphub-banner-intro {
        @include tiphubIntro;
    }

    .share {
        position: absolute;
        transform: rotate(270deg);
        bottom: size(350);

        @media screen and (max-width:576px) {
            bottom: size(200);
        }

        a {
            font-family: $familyPrimaryRegular;

            svg {
                height: size(22);
                width: size(22);
            }
        }
    }

    .tiphub-banner-middle {
        position: absolute;
        width: 100%;
        top: size(205);
        z-index: -1;

        @media screen and (max-width:767px) {
            display: none;
        }

        .left-align {
            position: relative;
            height: size(170);
            width: size(200);

            @media (min-width: 768px) and (max-width: 1024px) {
                width: size(150);
            }

            .animation {
                height: 100%;
                background-color: $green;
                width: size(60);
                display: block;
                position: absolute;
                z-index: 0;
                top: 0;
                animation: mymove 5s infinite;

                @keyframes mymove {
                    0% {
                        left: 0;
                    }

                    50% {
                        left: 100%;
                    }

                    100% {
                        left: 0;
                    }
                }
            }

            .content {
                margin-left: size(40);
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                hr {
                    border-top: 2px solid rgba(0, 0, 0, 0.9);
                    width: 46%;
                    margin-left: size(0);
                }

                p {
                    margin-bottom: size(0);
                }
            }
        }

        .right-align {

            // Circle Animation
            .circle {
                position: absolute;
                right: size(150);
                top: size(60);
                display: inline-block;
                width: size(28);
                height: size(28);
                border-radius: 50%;
                box-shadow: 4px -40px 60px 5px #d4d4d4 inset;
            }

            .loader {
                background: linear-gradient(to right, rgb(22, 113, 202) 50%, rgb(0, 0, 0) 50%);
                animation: spin 1s infinite linear;

                &::before {
                    display: block;
                    content: '';
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: size(20);
                    height: size(20);
                    background: $white;
                    border-radius: 50%;
                }
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }

            // Circle Animation

            ul {
                @include listing;
                width: size(80);

                li {
                    img {
                        border: $white solid size(1);
                        height: size(80);
                        border-radius: 50%;
                    }

                    &:nth-child(2) {
                        position: relative;
                        top: size(-20);
                    }

                    h6 {
                        text-align: center;
                    }
                }
            }
        }
    }

    .tiphub-banner-bottom {
        position: relative;

        .trangle {
            position: absolute;
            left: size(220);
            top: size(50);
            width: 0;
            height: 0;
            border-left: size(25) solid transparent;
            border-right: size(25) solid transparent;
            border-bottom: size(50) solid #25BDE2;

            @media screen and (max-width:767px) {
                left: size(20);
            }

            &::before {
                display: block;
                content: '';
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 18%);
                width: 0;
                height: 0;
                border-left: size(20) solid transparent;
                border-right: size(20) solid transparent;
                border-bottom: size(40) solid $white;
            }

            transform-origin: 50% 50%;
            animation: jump .5s linear alternate infinite;

            @keyframes jump {
                0% {
                    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
                }

                40% {
                    transform: translate3d(0, 30%, 0) scale3d(.7, 1.5, 1);
                }

                100% {
                    transform: translate3d(0, 100%, 0) scale3d(1.5, .7, 1);
                }
            }
        }
    }
}

/* Banner */

/* Console Your Givers */
.console-your-givers {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    h1 {
        width: 50%;
        margin: auto;

        @media screen and (max-width:767px) {
            width: 100%;
        }
    }

    .console-slider {
        .slick-list {
            width: 80%;
            margin: auto;

            @media (min-width: 768px) and (max-width: 1024px) {
                width: 90%;
            }

            @media screen and (max-width:767px) {
                text-align: center;
            }
        }

        .slick-arrow {
            box-shadow: none;
            border: $tiphubPrimaryTextColor solid size(1);
            width: size(45);
            height: size(45);
            border-radius: 50%;

            &.slick-prev {
                @media screen and (max-width:576px) {
                    left: 0;
                }

                &::before {
                    content: '';
                    background: url(../../public/assets/feather-ar.png);
                    display: block;
                    background-repeat: no-repeat;
                    height: size(14);
                    position: absolute;
                    width: size(14);
                    left: 50%;
                    top: 55%;
                    transform: translate(-50%, -50%);
                }
            }

            &.slick-next {
                @media screen and (max-width:576px) {
                    right: 0;
                }

                &::before {
                    content: '';
                    background: url(../../public/assets/feather-ar.png);
                    display: block;
                    background-repeat: no-repeat;
                    height: size(14);
                    position: absolute;
                    width: size(14);
                    left: 34%;
                    top: 34%;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .givers-box {
        padding: size(10) size(10);
        position: relative;
        min-height: size(120);

        .givers-box-align {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(-0%, -50%);
            width: 85%;

            @media (min-width: 768px) and (max-width: 1024px) {
                width: 90%;
            }

            @media screen and (max-width:767px) {
                width: 100%;
            }

            h4 {
                color: $green;

                @media (min-width: 768px) and (max-width: 1024px) {
                    font-size: size(16);
                }
            }

            h2 {
                font-family: $familyPrimaryBold;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (min-width: 768px) and (max-width: 1024px) {
                    font-size: size(18);
                }
            }
        }
    }
}

/* Console Your Givers */

/* Supports Layout */
.supports-system {
    @include supportSystemLayout;
}

/* Supports Layout */

/* How It Works */
.how-it-works {
    .tiphub-banner-intro {
        @include tiphubIntro;
        padding: size(60) 0;
    }

    aside {
        position: relative;

        .rotate-down-left {
            @include rotate-down-left;
            position: absolute;
            background-color: $white;
            padding: size(5);
            border-radius: 50%;
            left: size(120);
            top: size(120)
        }
    }

    .supports-system {
        @include supportSystemLayout;

        .supportSystemBox {
            border: $border-color solid size(1);
            padding: size(50) size(40);

            @media (min-width: 768px) and (max-width: 991px) {
                margin-bottom: size(15);
            }

            @media screen and (max-width:767px) {
                margin-bottom: size(15);
            }

            @media screen and (max-width:576px) {
                padding: size(30);
                margin-bottom: size(15);
            }

            img {
                height: auto;
            }

            &:hover {
                border: none;
                border: white solid size(1);
            }
        }
    }
}

/* How It Works */

/* Small efforts */
.small-efforts {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    .btn-primary {
        @include primaryButton;
        padding: size(20) size(50);
        border-radius: size(16);
        font-size: size(18);
    }

    .blog-slider {
        padding-top: size(60);

        .slick-list {
            height: size(400)
        }

        .slick-active {
            width: 5.6% !important;

            .blogs-box {
                @include blogSlider;
                padding: size(0);

                .blogs-box-bg {

                    .blog-box-content {
                        h1 {
                            font-size: size(22);

                            @media (max-width: 767px) {
                                font-size: size(22);
                            }
                        }
                    }
                }
            }

            @media (max-width: 767px) {
                width: 9.4% !important;
            }

            &.slick-current {
                width: 12% !important;

                .blogs-box {
                    .blogs-box-bg {
                        .blog-box-content {
                            h1 {
                                font-size: size(35);

                                @media (max-width: 767px) {
                                    font-size: size(22);
                                }
                            }
                        }
                    }
                }

                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 23% !important;
                }

                @media (max-width: 767px) {
                    width: 9.4% !important;
                }
            }
        }
    }
}

/* Small efforts */

/* Feature Event */
.feature-events {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    .btn-primary {
        @include primaryButton;
        padding: size(16) size(40);
        border-radius: size(16);
        font-size: size(18);
    }

    .feature-slider {
        .blogs-box {
            @include blogSlider;

            .blogs-box-bg {
                .ads-tag {
                    position: absolute;
                    top: size(20);
                    right: size(20);
                    background-color: $tiphubthemeBlue;
                    color: $white;
                    font-size: size(12);
                    padding: size(8) size(20);
                    border-radius: size(30);
                    font-family: $familyPrimarySemiBold;
                    z-index: 1;
                }
            }

            .content {
                padding: size(25) size(10);

                h5 {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                a {
                    font-size: size(16);
                }
            }
        }
    }
}

/* Feature Event */

/* Testimonial */
.testimonial {
    padding: size(0) size(0) size(0) size(100);
    background-image: linear-gradient(to right, #ffdedd, #fff);
    position: relative;
    height: size(650);
    margin-top: size(200);

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: size(0) size(0) size(0) size(20);
    }

    @media screen and (max-width:767px) {
        height: auto;
        margin-top: size(0);
        padding: size(30) size(15);
    }

    .testimonial-top {
        position: relative;

        .content-align {
            position: absolute;
            top: size(-220);

            @media screen and (max-width:767px) {
                position: inherit;
                top: size(0);
            }

            .rotate-down-left {
                @include rotate-down-left;
                position: absolute;
            }

            .box {
                position: absolute;
                background-color: $white;
                border-radius: size(6);
                padding: size(10) size(15);
                left: size(140);
                bottom: size(60);
                width: size(240);

                @media screen and (max-width:576px) {
                    left: size(40);
                    width: auto;
                }

                .content {
                    h5 {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: size(4);
                        color: $gray-300;
                        font-size: size(12);
                    }

                    h6 {
                        font-size: size(16);
                        font-family: $familyPrimarySemiBold;

                        .dollar {
                            vertical-align: bottom;
                            background-color: $green;
                            border-radius: size(4);
                            padding: size(2);
                            fill: $white;
                            font-size: size(20);
                        }
                    }

                    .share {
                        font-size: size(12);
                    }
                }

                .checkbox {
                    fill: $tiphubthemeBlue;
                }
            }
        }
    }

    .testimonial-right-align {
        position: absolute;
        right: 0;
        height: size(650);

        @media screen and (max-width:767px) {
            display: none;
        }

        .bg {
            width: size(400);
            height: 100%;
            position: relative;
            border-radius: 190px 0px 0px 390px;
            background: $darkBlue;

            .animation {
                position: relative;
                height: 100%;
                display: block;

                img {
                    position: absolute;
                    width: -webkit-fill-available;
                    top: 50%;
                    right: 0%;
                    transform: translate(0%, -50%);
                }
            }
        }
    }

    .testimonial-bottom-content {
        margin-top: size(280);

        @media screen and (max-width:767px) {
            margin-top: size(0);
        }

        .testimonial-headline {
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(-0%, -50%);

            @media screen and (max-width:767px) {
                position: inherit;
            }
        }

        .testimonial-slider {
            .slick-list {
                height: size(310);

                @media screen and (max-width:767px) {
                    height: size(250);
                }

                .slick-slide {
                    @media screen and (max-width:767px) {
                        padding-top: size(15);
                    }
                }

                .slick-active {
                    padding-bottom: size(10);
                    width: 15% !important;

                    @media screen and (max-width:767px) {
                        padding-top: size(15);
                    }

                    &.slick-current {
                        width: 15% !important;

                        @media screen and (max-width:767px) {
                            padding-top: size(15);
                        }

                        .testimonial_box {
                            position: relative;

                            &::before {
                                position: absolute;
                                content: '';
                                background-color: rgba(255, 255, 255, 0.5);
                                padding: size(35);
                                border-radius: size(20);
                                width: 97%;
                                height: 100%;
                                display: block;
                                top: size(10);
                                z-index: -1;

                                @media screen and (max-width:576px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .testimonial_box {
                    @include testimonialSliderBox;
                }
            }
        }
    }
}

/* Testimonial */

/* Footer */
.tiphub-footer {
    border-top: $border-color solid size(1);
    background-image: linear-gradient(to right, #faf3f3, #e1e8f2);
    padding: size(100) size(0);
    font-size: size(14);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    ul {
        @include listing;

        li {
            margin-bottom: size(8);

            a {
                color: $tiphubPrimaryTextColor !important;
            }
        }
    }

    a {
        color: $tiphubPrimaryTextColor;
        text-decoration: none;
        font-family: $familyPrimarySemiBold;
    }

    img {
        height: size(50);
    }

    p {
        width: 55%;
        margin: auto;
        font-family: $familyPrimarySemiBold;

        @media screen and (max-width:1024px) {
            width: 100%;
        }
    }

    .tiphub-social-links {
        @include listing;
        margin-bottom: size(15);

        li {
            display: inline-block;
            margin: size(0) size(6);

            svg {
                width: size(20);
                height: size(20);
            }
        }
    }

    .solutions {
        @media screen and (max-width:767px) {
            order: 1;
            margin-top: size(15);
        }
    }

    .footer-bottom {
        .footer-bottom-paragraph {
            @media screen and (max-width: 767px) {
                margin-top: size(15) !important;
                padding-top: size(0) !important;
            }
        }
    }
}

/* Footer */

/* Credential Modal */

.modal-backdrop {
    &.show {
        opacity: 0.4 !important;
    }
}

.frontend_modal {
    .modal-lg {
        @media screen and (min-width: 992px) {
            --bs-modal-width: 992px;
        }
    }

    .modal-content {
        border-radius: size(15);
    }

    .modal-background {
        background-image: linear-gradient(90deg, #daf8f9, #ffdcdb);
        border-radius: size(15);
        border: size(14) solid $white;
        position: relative;

        @media screen and (min-width: 768px) and (max-width:991px) {
            border: size(0) solid $white;
        }

        @media screen and (max-width:767px) {
            border: size(0) solid $white;
        }

        .modal-align {
            height: 70vh;
            display: flex;
            text-align: center;

            .modal-align-content {
                padding: size(20) size(50);
                margin: auto;

                h3 {
                    font-weight: 700;
                }

                .btn-link {
                    @include whiteButton;

                    svg {
                        font-size: 22px;
                        vertical-align: text-bottom;
                        margin: size(0) size(10);
                    }
                }

            }
        }

        form {
            @include credentialForm;
        }

        .login {
            left: size(48);
            height: auto;

            @media screen and (min-width: 768px) and (max-width:991px) {
                left: size(0);
                height: 100%;
            }

            @media screen and (max-width:767px) {
                left: size(0);
            }
        }

        .signUp {
            right: size(48);
            height: auto;

            @media screen and (min-width: 768px) and (max-width:991px) {
                right: size(0);
                height: 100%;
            }

            @media screen and (max-width:767px) {
                right: size(0);
            }
        }
    }

}

/* Credential Modal */

/* Profile */

.profile {
    .public_profile_bg {
        height: 100%;
    }

    .profile_banner {
        padding: size(60) size(0);

        @media screen and (max-width:576px) {
            padding: size(30) size(0);
            text-align: center;
        }

        .profile-image {
            @media screen and (max-width:576px) {
                height: size(150);
                margin-bottom: size(15);
            }
        }

        .profile-intro {
            height: 100%;
            position: relative;

            .profile-intro-content {
                position: absolute;
                top: 50%;
                left: 10%;
                transform: translate(0%, -50%);

                @media screen and (max-width:576px) {
                    position: inherit;
                    top: inherit;
                    left: inherit;
                    transform: inherit;
                }

                h2 {
                    font-family: $familyPrimaryBold;
                }

                .tiphub-social-links {
                    @include listing;

                    @media screen and (max-width:576px) {
                        display: flex;
                    }

                    li {
                        float: left;
                        margin-right: size(10);

                        @media screen and (max-width:576px) {
                            margin: auto;
                        }

                        a {
                            background-color: $white;
                            border-radius: 50%;
                            width: size(40);
                            height: size(40);
                            display: flex;

                            svg,
                            img {
                                margin: auto;
                                font-size: size(20);
                            }

                            &:hover {
                                transition: all 1s ease;
                                transform: scale(1.1);
                                box-shadow: $boxShadow;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Tipping App */

.tipping_apps {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    .tipping_apps_content {
        text-align: center;

        .qrSlider {
            width: 50%;
            margin: auto;

            @media screen and (max-width:576px) {
                width: 100%;
            }
        }

        .tipping_app_icon {
            @include tipping_app_icon;
            margin: size(30) size(0) size(0);

            @media screen and (max-width:576px) {
                width: 100%;
            }

            li {
                margin: size(0) size(12) size(16);
                box-shadow: $boxShadow;

                @media screen and (max-width:576px) {
                    float: inherit;
                    border-radius: size(30);
                }

                a {
                    padding: size(20) size(25);
                    width: inherit;
                    min-width: size(120);
                    height: size(120);

                    @media screen and (max-width:576px) {
                        display: flex;
                        height: auto;
                        width: 100%;
                        ;
                        padding: size(6) size(25);
                        border-radius: size(30);
                        text-align: center;
                    }

                    img {
                        // height: size(50);

                        @media screen and (max-width:576px) {
                            margin-right: size(10);
                        }
                    }

                    span {
                        display: block;
                        margin-top: size(10);

                        @media screen and (max-width:576px) {
                            margin-top: inherit;
                            margin: auto 0;
                        }
                    }
                }
            }
        }

        .btn-primary {
            @include primaryButton;
            padding: size(12) size(50);
            border-radius: size(12);
            font-size: size(18);
        }
    }
}

/* Tipping App */

/* QR_Code_Content App */

.qr_code_content {
    padding: size(60) size(0);

    @media screen and (max-width:576px) {
        padding: size(30) size(0);
    }

    .qrSlider {
        .qrSlider-box {
            margin: auto;

            img {
                width: 100%;
                height: size(300);
            }
        }
    }
}

/* QR_Code_Content App */

/* Profile */

/* Dashboard Common */

.dashboard-heading {
    display: flex;

    h2 {
        font-family: $familyPrimaryBold;
    }
}

.action {
    ul {
        @include listing;

        li {
            float: left;

            .btn {
                font-family: $familyPrimaryBold;

                svg {
                    margin-right: size(10);
                    vertical-align: sub;
                    font-size: size(16);
                }

                .editIcon {
                    fill: #ff4200;
                }

                .shareIcon {
                    fill: $twitter;
                }
            }
        }
    }
}

/* Dashboard Common */

/* Dashboard Banner */

.dashboard-banner {
    @include tiphub-banner;
    padding-bottom: size(60);

    .dashboard-left-align {
        padding: size(25) size(20);
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: size(10);

        .profile-dashboard-preview {
            padding: size(20);
            border-color: transparent;
            height: 100vh;
            overflow-y: scroll;

            .dashboard-heading {
                display: none;
            }

            .profile_banner {
                text-align: center;
                padding: size(0);

                .col-sm-4,
                .col-sm-8 {
                    width: 100%;
                }

                img {
                    height: size(150);
                    margin-bottom: size(15);
                }

                .profile-intro {
                    .profile-intro-content {
                        position: inherit;
                        top: 0%;
                        left: 0%;
                        transform: translate(0%, 0%);

                        .tiphub-social-links {
                            display: inline-flex;

                            li {
                                a {
                                    box-shadow: $boxShadow;

                                    img {
                                        height: size(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tipping_apps {
                padding: size(30) size(0);

                h1 {
                    font-size: 2rem;
                }

                .qrSlider {
                    width: 100%;
                }

                .tipping_app_icon {
                    li {
                        margin: size(0) size(0) size(16);
                        width: 100%;
                        border-radius: size(30);

                        a {
                            height: auto;
                            padding: size(10) size(25);

                            img {
                                margin-right: size(15);
                                display: inline;
                                // height: size(40);
                            }

                            span {
                                margin-top: inherit;
                                display: inline;
                            }
                        }
                    }
                }
            }

            .feature-events {
                padding: size(30) size(0);

                h1 {
                    font-size: 2rem;
                }

                .col-md-8,
                .col-md-4 {
                    width: 100%;
                }
            }
        }
    }

    .dashboard-right-align {
        padding: size(25) size(20);

        @media screen and (max-width:576px) {
            padding: size(15) size(0);
        }

        .accordion {
            @include accordion;
        }
    }
}

/* Dashboard Banner */


/* Discover Events */

.discover_events {
    .tiphub-banner {
        .tiphub-banner-bg {
            height: 100%
        }

        .tiphub-banner-intro {
            padding: size(60) 0 size(30);

            @media (min-width: 768px) and (max-width: 1024px) {
                padding: size(60) size(0) size(0);
            }

            @media screen and (max-width:576px) {
                padding: size(30) size(0) size(0);
            }
        }
    }

    .latest_events {
        padding-bottom: size(60);

        @media (min-width: 768px) and (max-width: 1024px) {
            padding-bottom: size(40);
        }

        @media screen and (max-width:576px) {
            padding-bottom: size(30);
        }

        .blogs-box {
            @include blogSlider;
            padding: size(0);

            .blogs-box-bg {
                height: size(350);
                margin: size(0);

                img {
                    margin: auto;
                    display: flex;
                }

                .blog-box-content {
                    background-color: rgba(0, 0, 0, 0.8);
                    border-radius: size(20);

                    .ads-tag {
                        position: initial;
                        background-color: transparent;
                        padding: size(0);
                        color: $white;
                    }

                    h4 {
                        color: $white;
                    }
                }

                &::after {
                    left: size(0);
                }
            }

            &.third_box {
                .blogs-box-bg {
                    .blog-box-content {
                        right: size(0)
                    }
                }
            }
        }

        .blogs-box-wrapper {
            position: relative;
            height: 100%;

            @media screen and (max-width:767px) {
                padding: size(20) size(0);
            }

            .second_box {
                bottom: size(0);
                position: absolute;
                width: 100%;

                @media screen and (max-width:767px) {
                    position: inherit;
                }

                .blogs-box-bg {
                    height: size(250);

                    .blog-box-content {
                        right: size(0)
                    }
                }
            }
        }
    }

    .feature-slider {
        .blogs-box {
            @include blogSlider;
            padding: size(0);
            padding-top: size(30);

            .blogs-box-bg {
                height: auto;
                margin: size(0);

                .blog-box-content {
                    width: 100%;
                    background-color: transparent;

                    .ads-tag {
                        position: initial;
                        background-color: transparent;
                        padding: size(0);
                    }

                    h4 {
                        color: $white;
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.8);
                        border-radius: size(0) size(0) size(20) size(20);
                    }
                }

                &::after {
                    left: size(0);
                }
            }
        }
    }
}

/* Discover Events */

/* Event Details */

.event-details {
    padding: size(60) size(0);

    @media screen and (max-width:767px) {
        padding: size(30) size(0);
    }

    .event-details-content {
        .progress-bar {
            padding: size(60) size(0);

            @media screen and (max-width:767px) {
                padding: size(50) size(0);
            }

            .donate-amount {
                position: relative;
                top: size(0);
                width: 100%;
                display: flex;

                @media screen and (max-width:576px) {
                    display: block;
                }

                h4 {
                    font-family: $familyPrimarySemiBold;

                    @media screen and (max-width:576px) {
                        position: absolute;
                        top: size(-24);
                    }
                }

                span {
                    font-family: $familyPrimaryLight;

                    b {
                        font-family: $familyPrimaryMedium;
                    }

                    @media screen and (max-width:576px) {
                        position: absolute;
                        top: size(18);
                        left: size(0);
                    }
                }
            }
        }
    }

    .event-details-aside {
        .donate-now {
            @include primaryButton;
            width: 100%
        }

        ul {
            @include tipping_app_icon;

            li {
                margin: size(4);

                a {
                    padding: size(0);
                    height: auto;
                    width: auto;
                }
            }
        }

        .blog {
            .blog-content {
                h6 {
                    font-family: $familyPrimaryLight;

                    b {
                        font-family: $familyPrimarySemiBold;
                    }
                }

                p {
                    font-family: $familyPrimaryLight;
                    color: $gray-400;
                }
            }

            .dropdown {
                .dropdown-toggle {
                    padding: size(0);
                    float: right;
                    line-height: size(0);

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

/* Event Details */

/* Custom Modal */

.event-custom-modal {
    width: 60% !important;

    @media (max-width: 992px) {
        width: 80% !important;
    }

    @media (max-width: 767px) {
        width: 100% !important;
    }
    .offcanvas-header {
        position: relative;
        padding: size(22) size(40);

        @media (max-width: 767px) {
            padding: size(20) size(10);
        }

        .btn-close {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 10px;
            border-radius: 50%;
            position: absolute;
        }

        h4 {
            margin: auto;
            font-weight: 700;
        }
    }

    .offcanvas-body {
        padding: 0.5rem 3rem 2rem;

        @media (max-width: 767px) {
            padding: 0.5rem size(10) 2rem;
        }
        .ticket_module {
            h5 {
                font-family: $familyPrimaryRegular;
                svg {
                    vertical-align: sub;
                }
            }
        }
        .fundraising-event-selection {
            .btn{
              border-color: $darkBlue;
              min-height: size(120);
              background-color: initial;
              width: 100%;
              color: $darkBlue;
              &.selected {
                outline: $darkBlue solid size(2);
              }
            }
        }
        .smaller-button {
            .btn{
              border-color: $darkBlue;
              min-height: size(60);
              background-color: initial;
              width: 100%;
              color: $darkBlue;
              .checkbox_custom {
                height: size(25);
              }
              &.selected {
                outline: $darkBlue solid size(2);
              }
            }
        }
       .border-remover {
          border: none;
       }
    }
}
.ticket_model {
    @media (max-width: 567px) {
        .counter {
            text-align: center;
        }
        .total {
            width: 100%;
        }
    }
}
.physical-ticket-modal {
    ul {
        &.list-teams {
            list-style: none;
            padding: 0;
            margin: 0;
            @media (max-width: 767px) {
                margin-top: size(10);
                li {
                    text-align: center;
                }
            }
        }
    }
}
/* Custom Modal */

/* Events */
.event_mail_logo{
    @include email-logo-card
}
.bg-white-glass {
    background-color: #ffffff5c;
    border-radius: 6px;
    padding: 20px;
    margin: 0;
    margin-bottom: 20px;
}
/* Events */

/* Pricing */
.pricing-wrapper{
    @include pricing
}
/* Pricing */

.list-group-item.active{
    // color: white !important;
    border-radius:6px;
    background-color: #595d6429 !important;
}
.list-group-item{
    background-color: unset !important;
    border: 0 !important;
    margin: 2px 0;
}

.search-container{
    background-color: #efefef;
    padding: 10px;
}

.settings-sidebar svg{
    font-size: 25px;
    margin-right: 10px;
    transform: scale(0.8);
    transition:all 0.3s;
}

.settings-sidebar .active svg{
    transform: scale(1);
}


.custom-label .form-label{
    margin-top: 15px;
    margin-bottom: 0px !important;
}

// .reports-overview .nav-tabs{
//     border: 0;
// }


// .reports-overview .nav-item .active{    
//     background-color: #00345a !important;
    
//     color: #fff !important;
//     border-radius: 6px !important;
// }

.reports-card .card{
    transition: 0.3s;
}

.reports-card .card:hover{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1);
}

.m-table{
    min-height: 300 !important; 
    background-color:#ffffffb5 !important; 
    border-radius:6px !important;
}

.payment-icon-box {
    background-color: #eee;
    padding: 10px;
    border-radius: 6px;
    width: 95% !important;
    margin: auto;
}
.reports-overview .payment-icon {
    height: 50px;
    overflow: hidden;
    margin: 0px 20px;
}
.reports-overview .payment-icon ul {
    display: flex;
    left: 50%;
    list-style: none;
    overflow-x: scroll;
    position: absolute;
    transform: translate(-50%);
    padding: 10px 0px;
    width: 91%;
    padding-top: 5px;
}

.reports-overview .payment-icon ul li {
    float: left;
    margin: 0 5px;
}

.reports-overview .payment-icon ul li img {
    width: 1.78571rem;
}


.reports-overview .reports .progress {
    background: none;
    box-shadow: none;
    float: left;
    height: 150px;
    line-height: 150px;
    margin: 5px 5px 3.57143rem;
    position: relative;
    width: 150px;
}

.reports-overview .reports .progress .progress-left {
    left: 0;
}
.reports-overview .reports .progress .progress-right {
    right: 0;
}
.reports-overview .reports .progress .progress-value {
    border-radius: 50%;
    color: #2a3030;
    font-size: 2.14286rem;
    font-weight: 900;
    height: 90%;
    left: 5%;
    line-height: 135px;
    position: absolute;
    text-align: center;
    top: 5%;
    width: 90%;
    z-index: 1;
}

.reports-overview .reports .progress:after {
    background-clip: content-box,border-box,border-box,border-box,border-box;
    background-image: linear-gradient(#fff,#fff),linear-gradient(30deg,#b7daed 36%,#b7daed 0),linear-gradient(120deg,#b7daed 36%,#b7daed 0),linear-gradient(300deg,#fe675c 36%,#fe675c 0),linear-gradient(210deg,#fe675c 36%,#fe675c 0);
    background-origin: content-box,border-box,border-box,border-box,border-box;
    background-position: 50%,0 0,100% 0,0 100%,100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%,50% 50%,50% 50%,50% 50%,50% 50%;
    border: 6px solid transparent;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    width: 100%;
}

.reports-overview .reports .progress.blue .progress-left .progress-bar {
    -webkit-animation: loading-2 1.5s linear 1.8s forwards;
    animation: loading-2 1.5s linear 1.8s forwards;
}
.reports-overview .reports .progress.blue .progress-bar {
    border-color: #1547ff;
}

.reports-overview .reports .progress .progress-right {
    right: 0;
}
.reports-overview .reports .progress .progress-right .progress-bar {
    -webkit-animation: loading-1 1.8s linear forwards;
    animation: loading-1 1.8s linear forwards;
    border-bottom-left-radius: 80px;
    border-right: 0;
    border-top-left-radius: 80px;
    left: -100%;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}
.reports-overview .reports .progress .progress-value span {
    font-size: 1rem;
    font-weight: 600;
    vertical-align: super;
}

.reports-overview .v-line {
    border-left: 1px solid #d7e0e9;    
}

.reports-overview .payment-apps {
    margin: auto;
    width: -webkit-max-content;
    width: max-content;
}
.reports-overview .payment-apps ul {
    border: 0;
    background-color: #e0e7f1;
    border-color: transparent;
    border-radius: .71429rem;
    float: left;
    padding-left: 0;
    width: 100%;
}
.reports-overview .payment-apps ul li {
    box-sizing: border-box;
    float: left;
    list-style: none;
    padding: .71429rem;
    text-align: left;
}
.reports-overview .payment-apps ul li .card-img {
    display: flex;
}
.reports-overview .payment-apps ul li .card-img img {
    margin: auto;
    width: auto;
}

.reports-overview .payment-apps-slider .item img {
    height: 2.5rem;
}